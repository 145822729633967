
import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Icon from "../components/icon"

function SpeakingPage({ location, data }) {

  const events = data.allEventsYaml.nodes

  return (
    <Layout location={location}>
      
      <Seo 
        title="Speaking appearances of Andrey Adamovich" 
        metaDescription="Speaking appearances of Andrey Adamovich"
      />

      <section className="mt-8 lg:mt-18 mb-20">

        <header>
          <h1>Speaking</h1>
          <p className="ml-1 mt-2">So many events, so many good memories.</p>
        </header>

        <div className="flex flex-col gap-6 mt-16 lg:flex-row">
          <div className="order-1 lg:order-none">
            <ol className="space-y-13">
              {events.map((event, eventIndex) => {
                return (
                  <li key={eventIndex}>
                    <article>
                      <header>
                        <h2>
                          <a className="flex items-center gap-3 -ml-2" href="#">
                            <Icon id="calendar" size={60} />
                            <span>{event.title}</span>
                          </a>
                        </h2>
                        <small>{new Intl.DateTimeFormat('en-GB', { dateStyle: "full" }).format(new Date(event.date))}</small>
                      </header>
                      {event.sessions.map((session, sessionIndex) => {
                        return (
                          <section key={sessionIndex} className="mt-4">
                            <p className="text-black font-bold">{session.title}</p>
                            <div className="mt-2.5 flex gap-3">
                              {session.video ?
                                <a href={session.video} className="flex items-center gap-2 px-4 py-2 text-xl font-bold uppercase duration-200 border-2 border-slate-400 text-slate-400 hover:border-sky-900 hover:bg-sky-900 hover:text-white" >
                                  <Icon id="video-call" size={32} /> video
                                </a> : <></>
                              }
                              {session.slides ?
                                <a href={session.slides} className="flex items-center gap-2 px-4 py-2 text-xl font-bold uppercase duration-200 border-2 border-slate-400 text-slate-400 hover:border-sky-900 hover:bg-sky-900 hover:text-white">
                                  <Icon id="trainer" size={32} /> slides
                                </a> : <></>
                              }
                            </div>
                          </section>
                        )
                      })}
                    </article>
                  </li>
                )
              })}
            </ol>
          </div>

          <div className="flex items-start justify-center flex-1">
            <img
              src="/images/speaker-andrey.jpg"
              srcSet="/images/speaker-andrey@2x.jpg 2x"
              alt="Andrey speaking at the DevOps Unicorns conference in Riga 2019"
              width="500"
              height="659"
              loading="lazy" />
          </div>

        </div>

        <div className="absolute -z-100 bg-gray-100 top-[66vh] lg:top-[77vh] left-0 right-0 h-[668px]" />

      </section>

    </Layout>
  )
}

export default SpeakingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allEventsYaml {
      nodes {
        cover
        date
        id
        place
        url
        title
        sessions {
          slides
          time
          title
          video
        }
      }
    }
  }
`
